<template>
  <div>
    <a-table :columns="columns" :data-source="items" size="small" :pagination="pagination" @change="tableChange" :loading="loading">
      <div slot="avg_retail_price" slot-scope="value">{{NP.round(value, 2)}}</div>
    </a-table>
  </div>
</template>

<script>
  import NP from 'number-precision'

  export default {
    name: 'UserPane',
    props: ['items', 'loading', 'pagination'],
    data() {
      return {
        NP,
        columns: [
          {
            title: '供应商编号',
            dataIndex: 'supplier_number',
            key: 'supplier_number',
          },
          {
            title: '供应商名称',
            dataIndex: 'supplier_name',
            key: 'supplier_name',
          },
          {
            title: '最大值',
            dataIndex: 'max_purchase_price',
            key: 'max_purchase_price',
          },
          {
            title: '最小值',
            dataIndex: 'min_purchase_price',
            key: 'min_purchase_price',
          },
          {
            title: '平均值',
            dataIndex: 'avg_purchase_price',
            key: 'avg_purchase_price',
          },
          {
            title: '总数量',
            dataIndex: 'total_purchase_quantity',
            key: 'total_purchase_quantity',
          },
          {
            title: '销售总金额',
            dataIndex: 'total_purchase_amount',
            key: 'total_purchase_amount',
          }
        ],
      };
    },
    methods: {
      tableChange(pagination, filters, sorter) {
        this.$emit('tableChange', pagination, filters, sorter)
      },
    }
  }
</script>
